.text-banner, .single-work-banner {
    background-size: contain;
    background-attachment: fixed;
    .container {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            width: 2px;
            height: 51%;
            background-color: $ai-black;
            left: 12px;
            top: 0;
            max-height: 0;
            transition: max-height 4s ease;
        }
        &.animating{
            &::before{
                max-height: 100%;
            }
        }
    }

    .row {
        align-items: center;
        padding-top: 7rem;
        padding-bottom: 8rem;
    }

    h1 {
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
    }

    h2 {
        font-size: 42px;
        font-weight: 600;
    }

    p {
        font-size: 22px;
        font-weight: 300;
        line-height: 2;
    }

    &__heading {
        padding-left: 45px;
        @media(max-width: 767px) { padding-left: 15px; }
    }
}
.single-work .text-banner{
    .row {
        padding-top: 3rem;
    }
    .container{
        &::before{
            height: 47% !important;
            @media(max-width: 767px) {
                height: 24% !important;
            }
        }
    }
}

.text-banner {
    .container {
        @media(max-width: 767px) { 
            padding-top: 50px;

            h1 {
                padding-left: 15px;
            }
        }
    }

    .stats {
        display: flex;
        gap: 60px;
        @media(max-width: 767px) { flex-wrap: wrap; }

        img {
            width: 162px;
        }

        .number {
            p {
                line-height: 1;
            }
            strong {
                font-size: 70px;
                font-weight: 700;
            }
        }

        .stat {
            width: 32%;
            @media(max-width: 767px) { width: 40%; }
        }

        .text {
            p, strong {
                font-size: 12px;
            }

            strong {
                display: block;
                font-weight: 600;
            }
        }
    }


    .heading {
        strong {
            font-weight: 600;
        }
    }
}

.single-work-banner {

    h2 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 2.5rem;
    }

    img {
        margin-bottom: 2.5rem;
    }

    .sharelinks {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 2.5rem;
        padding: 10px 0;

        img {
            width: 25px;
        }

        button {
            background-color: transparent;
            border: none;
        }
    }
}

.video-bg-banner {
    position: relative;
    height: 100vh;
    overflow: hidden;
    &__video {
        iframe, .mobile-placeholder {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            pointer-events: none;
            transform-origin: center;
            transform: scale(1.4);
        }

        .mobile-placeholder {
            object-fit: cover;
            object-position: center;
        }

        iframe {
            @media(max-width: 991px) { display: none; }
        }
    }

    img {
        position: absolute;
        left: 0;
        top: 85px;
        bottom: 0;
        right: 0;
        margin: auto;
        //transform: translate(-50%, -50%);
        width: 30%;
        @media(max-width: 767px) {
            width: 60%;
        }
    }
    .ai-video-bg-banner__loader{
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 10;
        transition: background-color 0.5s ease;
        pointer-events: none;
        .ai-video-bg-banner__loader-lottie{
            width: 28%;
            height: fit-content;
            @media (max-width: 767px) {
                width: 60%;
            }
        }
        &.loading{
            background-color: black;
        }
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: fit-content;
        height: 80%;
        //background-color: white;
        z-index: 11;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        overflow: visible;

        .modal-content {
            position: relative;
            z-index: 1;
            background-color: transparent;
            width: fit-content;
        }

        iframe {
            aspect-ratio: 16/9;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media(max-width: 767px) {
                max-height: 350px;
                max-width: 90vw;
            }
            @media(max-width: 500px) {
                max-height: 230px;
            }
        }
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($ai-black, .9);
        opacity: 0.5;
        z-index: 11;
    }

    .close {
        position: fixed;
        z-index: 11;
        top: 10px;
        right: 10px;
    }
}

.contact-banner {
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-attachment: fixed;
    h1, h4, a, p {
        color: $ai-white;
    }

    .details {
        @media(max-width: 767px) { gap: 25px; }
    }

    .social-icon {
        width: 22px;
        position: relative;
        svg {
            width: 100%;
            fill: $ai-white;
            position: relative;
            z-index: 1;
        }

        &::before {
            content: '';
            position: absolute;
            width: 36px;
            height: 36px;
            background-color: $ai-white;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transition: $transition;
            transform: translate(-50%, -50%) scale(0);
            transform-origin: center;
        }

        &:hover {
            opacity: 1;
            svg {
                fill: $ai-black;
            }

            &::before {
                transform: translate(-50%, -50%) scale(1);
            }
        }
    }

    h1 {
        font-size: 66px;
        font-weight: 700;
        @media(max-width: 991px) {
            font-size: 50px;
        }
    }

    p {
        font-size: 22px;
        font-weight: 300;
        line-height: 2;
    }

    .details {
        p, a {
            font-size: 20px;
            text-decoration: none;
            font-weight: 300;
        }

        h4 {
            font-weight: 700;
            font-size: 20px;
        }

        a {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                width: 0;
                height: 2px;
                background-color: $ai-white;
                bottom: -5px;
                left: 0;
                transition: $transition;
            }

            p {
                display: inline-block;
                line-height: 1;
            }

            &:hover {
                opacity: 1;
                &::before {
                    width: 100%;
                }
            }
        }
    }

    .links {
        display: flex;
        gap: 20px;
        align-items: center;

        img {
            width: 30px;
            height: 50px;
        }

        a {
            &:first-child {
                img {
                    width: 40px;
                }
            }
        }
    }
}

.error-banner {
    h1, h4, p {
        color: $ai-white;
    }

    h1 {
        font-size: 66px;
        font-weight: 700;
        margin-bottom: 2rem;
    }

    p {
        font-size: 22px;
        font-weight: 300;
        line-height: 2;
        margin-bottom: 4rem;
    }

    .row {
        padding-top: 0;
        padding-bottom: 0;
        height: 100vh;
        align-items: center;
    }

    .links {
        display: flex;
        gap: 50px;
    }
}

@keyframes bannerLine {
    0% {
        max-height: 0;
    }
    100% {
        max-height: 100%;
    }
}