.culture {
    padding-top: 116px;
    @media(max-width: 767px) { padding-top: 100px; }
    .text-banner {
        .row {
            @media(min-width: 992px) { padding-top: 9rem; }
        }

        .container {
            &::before {
                height: 53.5%;
                @media(max-width: 767px) { 
                    height: 25%;
                }
            }
        }
    }
    .content-image {
        @media(max-width: 767px) { overflow: hidden; }
        .row {
            align-items: center;
        }

        img {
            width: 100%;
        }

        .text.order-2 {
            padding-left: 12rem;
            @media(max-width: 767px) { padding-left: 15px; }

            &.order-md-1 {
                padding-left: 15px;
                padding-right: 12rem;
                @media(max-width: 767px) { padding-right: 15px; }
            }
        }

        &.full-width {
            .text.order-2 {
                &.order-md-1 {
                    padding-left: 6.5rem;
                    padding-right: 12rem;
                    @media(max-width: 767px) { 
                        padding-left: 15px; 
                        padding-right: 15px;
                    }
                }
            }
        }

        .text {
            @media(max-width: 767px) { padding-top: 50px; }
        }
    }

    .cta {
        .content {
            padding-top: 12rem;
            @media(max-width: 767px) { padding-top: 2rem; }
        }
    } 

    .large-heading {
        @media(min-width: 992px) {
            padding-bottom: 3.5rem;
        }
        h2 {
            font-weight: 400;
            font-size: 66px;
            @media(max-width: 991px) { font-size: 40px; }
        }

        .row {
            padding-top: 7rem;
        }
    }

    #the-team {
        @media(min-width: 992px) {
            padding-top: 4rem;
            padding-bottom: 1.5rem;
        }

        p {
            font-weight: 300;
        }

        h2 {
            font-size: 42px;
            line-height: 1.5;
            margin-bottom: 18px;
            font-weight: 600;
        }

        .slick-dots {
            display: none !important;
        }

        .intro-content {
            padding-bottom: 2rem;
            p {
                font-size: 22px;
            }
        }
    }

    .process {
        overflow: hidden;
       @media(min-width: 992px) {
            padding-top: 0;
            padding-bottom: 4.5rem;
        }
    }

    .single-slider-wrapper {
        .single-slider {
            h2 {
                font-size: 46px;
            }
        }
    }
}