.home {
    #lottie {
        animation: opacity .5s forwards;
        animation-delay: 2.5s;
    }
    .navigation {
        border-bottom: 1px solid $ai-light-grey;
    }
    #who-we-are {
        padding-top: 8rem;
        background-size: cover;
        background-attachment: fixed;
        @media(max-width: 767px) {
            background-size: 250% 100%;
            background-attachment: initial;
        }
        h2, p {
            color: $ai-white;
        }

        h2 {
            font-size: 74px;
            font-weight: 400;
            margin-bottom: 2rem;
            @media(max-width: 991px) { font-size: 50px; }
        }

        p {
            font-weight: 300;
            font-size: 19px;
            @media(min-width: 1200px) { padding-right: 7rem; }
        }

        .col-md-8 {
            @media(min-width: 1200px) { padding-left: 8rem; }
        }

        .container {
            &:last-child {
                padding-bottom: 8rem;
            }
        }
    }

    #selected-projects {
        padding-top: 5rem;
        padding-bottom: 5rem;
        background-size: cover;
        background-attachment: fixed;
        @media(max-width: 767px) {
            background-attachment: initial;
            background-size: contain;
        }

        h2 {
            font-size: 60px;
            font-weight: 600;
            line-height: 1.5;
            @media(min-width: 1200px) { padding-right: 6rem; }
            @media(max-width: 991px) { font-size: 50px; }
        }

        p {
            font-size: 22px;
            font-weight: 300;
            margin-bottom: 2.5rem;
            strong {
                font-weight: 700;
            }
        }

        .logos {
            display: flex;
            gap: 20px;
            @media(max-width: 991px) { flex-wrap: wrap; }
            p {
                font-size: 13px;
                margin-bottom: 0;
                display: flex;
                gap: 10px;
                @media(max-width: 767px) { 
                    display: block; 
                    text-align: center;
                }
            }

            img {
                margin-bottom: 20px;
                margin-left: auto;
                margin-right: auto;
                display: block;
                height: 84px;
                width: 80%;
            }

            .logo {
                &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                    img {
                        width: 100%;
                    }
                }
                @media(min-width: 767px) { width: 40%; }
            }
        }
    }

    #our-services {
        padding-top: 4rem;
        padding-bottom: .5rem;

        .copy {
            p {
                font-size: 23px;
            }
        }

        .intro-text {
            .text {
                @media(min-width: 1280px) {
                    padding-left: 7rem;
                    padding-right: 6rem;
                }
            }
        }

        .large-title {
            h2 {
                margin-bottom: 1rem;
            }
        }
    }

    .logos-wrapper {
        padding-bottom: 3rem;
        padding-top: 2.5rem;
        .row {
            padding-top: 0;
        }

        .logos {
            > div {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            img {
                width: 12%;
                margin-right: 80px;
                margin-bottom: 3.5rem;
                height: auto;
                @media(max-width: 767px) {
                    width: 25%;
                    margin-right: 15px;
                }
            }
        }
    }

    .navigation {
        background-color: transparent;

        .logo {
            svg {
                fill: $ai-white;
            }
        }

        .social-icon {
            svg {
                fill: $ai-white;
            }

            &::before {
                background-color: $ai-white;
            }

            &:hover {
                svg {
                    fill: $ai-black;
                }
            }
        }

        .btn {
            &__dark {
                background-color: $ai-white;
                color: $ai-black;
                //border: 1px solid $ai-white;
                &:after{
                    background-color: $ai-white;
                }
                &:hover {

                    //background-color: $ai-black;
                    color: $ai-white;
                }
            }
        }

        &__toggler {
            span {
                background-color: $ai-white;
            }
        }

        &.nav-open {
            background: rgba($ai-black, .8);
        }

        &.scrolled {
            background-color: $ai-white;

            .logo {
                svg {
                    fill: $ai-black;
                }
            }
            
            .social-icon {
                svg {
                    fill: $ai-black;
                }

                &::before {
                    background-color: $ai-black;
                }

                &:hover {
                    svg {
                        fill: $ai-white;
                    }
                }
            }

            .btn {
                &__dark {
                    background-color: $ai-black;
                    color: $ai-white;
                    border: none;
                    &:after{
                        background-color: $ai-black;
                    }
                    &:hover {
                        background-color: $ai-white;
                        color: $ai-black;
                    }
                }
            }

            .navigation__toggler {
                span {
                    background-color: $ai-black;
                }
            }
        }
    }
}

//Loading Animation
.home{
    .navigation{
        a.logo{
            transition: transform .5s .5s ease !important;
            //transform: scale(1);
            svg{
                image-rendering: optimizeSpeed;
            }
        }
        .navigation__items{
            .navigation__socials{
                .social-icon{
                    svg{
                        transition: transform .5s .5s ease !important;
                        image-rendering: optimizeSpeed !important;
                    }
                }
            }
            .btn{
                transition: transform .5s .5s ease;
            }
            .navigation__toggler{
                transition: transform .5s .5s ease;
            }
        }
    }
    .next-section{
        transition: opacity .5s .5s ease, transform .5s .5s ease;
        transform: translateX(0);
    }
}
.loading{
    overflow: hidden;
    .navigation{
        border: none;
        a.logo{
            transform: scale(0);
        }
        .navigation__socials{
            .social-icon{
                svg{
                    transform: scale(0) !important;
                }
            }
        }
        a.btn{
            transform: scale(0) !important;
        }
        .navigation__toggler{
            transform: scale(0) !important;
        }
    }
    .next-section{
        opacity: 0;
        transform: translateX(20px);
    }
}

@keyframes opacity {
    0% { opacity: 1; }
    100% { opacity: .2; }
}