.error-page {
    .navigation {
        background-color: transparent;

        svg {
            fill: $ai-white;
        }

        .btn {
            &__dark {
                background-color: $ai-white;
                color: $ai-black;
                border: none !important;
                &:hover {
                    background-color: $ai-black;
                    color: $ai-white;
                }

                &::after {
                    background-color: $ai-white;
                }
            }
        }

        &.scrolled {
            .btn {
                &::after {
                    background-color: $ai-black;
                }
            }
        }

        &__toggler {
            span {
                background-color: $ai-white;
            }
        }

        &.nav-open {
            background: rgba($ai-black, .8);
        }

        &.scrolled {
            background-color: $ai-white;
            svg {
                fill: $ai-black;
            }

            .btn {
                &__dark {
                    background-color: $ai-black;
                    color: $ai-white;
                    border: 1px solid $ai-black;
                    &:hover {
                        background-color: $ai-white;
                        color: $ai-black;
                    }
                }
            }

            .navigation__toggler {
                span {
                    background-color: $ai-black;
                }
            }
        }
    }

    .error-banner {
        position: relative;
        &::before, &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        &::after {
            background-image: url('../../assets/images/odd-pattern.svg');
            background-size: cover;
            opacity: .3;
        }
        &::before {
            background-color: rgba($ai-black, .7);
            mix-blend-mode: multiply;
        }
    }
}