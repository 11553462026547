.news {

    .navigation {
        background-color: transparent;
        border-bottom: 1px solid $ai-light-grey;
        &.scrolled {
            background-color: $ai-white;
        }
    }

    .text-banner {
        padding-top: 116px;
        @media(max-width: 767px) { padding-top: 101px; }
        margin-bottom: 5rem;

        .row {
            @media(max-width: 767px) {
                padding-bottom: 0;
            }
        }
        .container {
            &::before {
                height: 51%;
                @media(max-width: 767px) { 
                    height: 28%;
                }
            }
        }
    }

    .post-categories {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding-left: 0;
        gap: 50px;
        border-bottom: 1px solid $ai-dark-grey;
        @media(max-width: 991px) { gap: 30px; }
        @media(max-width: 767px) { gap: 20px; }

        li {
            font-size: 16px;
            padding-top: 20px;
            padding-bottom: 20px;
            text-transform: uppercase;
            @media(max-width: 767px) {
                width: 100%;
                padding-top: 5px;
                padding-bottom: 5px;
            }
            &:last-child {
                //display: none;
            }
            &:hover {
                cursor: pointer;
            }
            &.active {
                border-bottom: 2px solid $ai-black;
            }
        }
    }

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
        transition: transform .8s ease;
        object-position: center center;
    }


    .blog-posts {
        .blog-post {
            margin-bottom: 3rem;
            &:first-child {
                display: none;
            }

            .image {
                position: relative;
                overflow: hidden;
                height: 260px;

                &::before {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    left: 0;
                    bottom: 0;
                    z-index: 1;
                    background-color: rgba($ai-black, .6);
                    transition: $transition;
                }
            }
            
            a {
                &:hover {
                    opacity: 1;

                    .image {
                        img{
                            transform: scale(1.12);
                        }
                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        img {
            aspect-ratio: 9/6;
        }

        .image {
            position: relative;
            margin-bottom: .5rem;

            .post-cat {
                position: absolute;
                background-color: $ai-black;
                padding: 5px 15px;
                color: $ai-white;
                text-decoration: none;
                top: 17px;
                left: 0;
                font-size: 14px;
                border-radius: 0 100px 100px 0;
            }
            
        }

        a {
            color: $ai-black;
            text-decoration: none;
        }

        h4 {
            font-weight: 600;
        }

        .post-date {
            font-size: 14px;
            color: $ai-dark-grey; 
            font-weight: 600;
            margin-bottom: .5rem;
        }
    }

    .latest-post-banner{
        text-decoration: none;
        color: $ai-black;
        &:hover {
            opacity: 1;

            img {
                transform: scale(1.12);
            }
        }

        .image {
            overflow: hidden;

            img {
                transition: $transition;
                transform-origin: center;
            }
        }
        .row {
            align-items: center;

            p {
                font-weight: 300;
            }

            h1 {
                font-weight: 600;
                margin-bottom: 2rem;
            }

            .latest-post-date {
                font-size: 16px;
                color: $ai-dark-grey; 
                font-weight: 600;
                margin-bottom: 2rem;
            }

            .latest-post-category {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 2rem;
            }

            img {
                @media(max-width: 767px) {
                    margin-bottom: 2rem;
                }
            }
        }
    }

    .pagination {
        display: flex;
        gap: 20px;
        padding-bottom: 5rem;
        button {
            background-color: transparent;
            border: none;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            font-weight: 600;
            transition: $transition;
            &.btn {
                display: none;
            }
            &.active, &:hover {
                background-color: rgba($ai-black, .14);
            }
        }
    }
}

.single-post {
    .navigation {
        background-color: transparent;
        border-bottom: 1px solid $ai-light-grey;

        svg {
            fill: $ai-white;
        }

        .btn {
            color: $ai-black;

            &::after {
                background-color: $ai-white;
            }

            &:hover {
                color: $ai-white;
            }
        }

        .navigation__toggler span {
            background-color: $ai-white;
        }

        a {
            &::before {
                background-color: $ai-white;
            }
            &:hover {
                svg {
                    fill: $ai-black;
                }
            }
        }
        &.scrolled {
            background-color: $ai-white;

            svg {
                fill: $ai-black;
            }

            .btn {
                color: $ai-white;

                &::after {
                    background-color: $ai-black;
                }

                &:hover {
                    color: $ai-black;
                }
            }

            .navigation__toggler span {
                background-color: $ai-black;
            }

            a {
                &::before {
                    background-color: $ai-black;
                }
                &:hover {
                    svg {
                        fill: $ai-white;
                    }
                }
            }
        }
    }
    .tag {
        padding: 10px 25px;
        border-radius: 100px;
        font-size: 14px;
        background-color: $ai-black;
        color: $ai-white;
        width: fit-content;
        font-weight: 700;

    }

    .post-content {
        padding-top: 4rem;
        padding-bottom: 4rem;
        @media(min-width: 768px) { padding-right: 10rem; }
        img {
            width: 100%;
            height: auto;
        }

        h3 {
            font-weight: 600;
            font-size: 40px;
            margin-bottom: 1.5rem;
        }

        h2 {
            padding-top: 1.5rem;
            margin-bottom: 1.5rem;
        }

        p {
            font-size: 18px;
            font-weight: 300;
            line-height: 2;
        }

        a {
            font-weight: 600;
            color: $ai-black;
            text-decoration: none;
            position: relative;

            &::before {
                content: '';
                display: inline-block;
                width: 0;
                height: 1px;
                background-color: $ai-black;
                position: absolute;
                bottom: -5px;
                left: 0;
                transition: $transition;
            }
            
            &:hover {
                opacity: 1;
                &::before {
                    width: 100%;
                }
            }
        }
    }

    .single-post-banner {
        background-size: cover;
        min-height: 90vh;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 6rem;
        padding-top: 116px;
        @media(max-width: 767px) { padding-top: 140px; }

        .container {
            position: relative;
            z-index: 1;
            color: $ai-white;

            h1 {
                font-size: 72px;
                font-weight: 400;
                @media(max-width: 767px) { font-size: 40px; }
            }

            p {
                font-size: 22px;
                font-weight: 600;
            }
        }

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 1;
            background-color: rgba($ai-black, .6);
        }
    }


    .post-links {
        display: flex;
        @media(max-width: 767px) {
            flex-wrap: wrap;
        }
        a {
            display: flex;
            height: 300px;
            width: 50%;
            position: relative;
            align-items: center;
            text-decoration: none;
            transition: all .3s;
            gap: 50px;
            background-color: $ai-white;
            z-index: 1;
            
            @media(max-width: 767px) {
                width: 100%;
            }

            .icon {
                display: inline-block;
                width: 30px;
                height: 30px;
                min-width: 30px;
                background-size: 100%;
                background-repeat: no-repeat;
                transition: $transition;
            }

            &.next {
                justify-content: flex-start;
                padding-left: 100px;
                padding-right: 20px;
                @media(max-width: 767px) {
                    padding-left: 30px;
                }

                &::before {
                    background: rgba($ai-black, .5);
                }

                .icon {
                    background-image: url('../../assets/images/icons/The-Odd-Number-Arrow-Right-Dark.svg');
                }
            }
            &.prev {
                justify-content: flex-end;
                text-align: right;
                padding-right: 100px;
                padding-left: 20px;
                @media(max-width: 767px) {
                    padding-right: 30px;
                }

                &::before {
                    background: rgba($ai-black, .5)
                ;
                }

                .icon {
                    background-image: url('../../assets/images/icons/The-Odd-Number-Arrow-Left-Dark.svg');
                }
            }

            p {
                color: $ai-black;
                font-weight: 600;
            }
            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                transition: $transition;
                z-index: 1;
                opacity: 0;
            }

            img {
                transition: $transition;
                opacity: 0;
            }

            h4 {
                color: $ai-black;
            }

            &:hover {
                color: $ai-white;
                opacity: 1;

                p {
                    color: $ai-white;
                }
                h4 {
                    color: $ai-white;
                }
                &::before {
                    opacity: 1;
                }

                img {
                    opacity: 1;
                }

                &.next {
                    .icon {
                        background-image: url('../../assets/images/icons/The-Odd-Number-Arrow-Right-Light.svg');
                    }
                }
                &.prev {
                    .icon {
                        background-image: url('../../assets/images/icons/The-Odd-Number-Arrow-Left-Light.svg');
                    }
                }
            }

            h4 {
                font-weight: 600
            }

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                left: 0;
                top: 0;
            }

            i, div {
                position: relative;
                z-index: 1;
            }
        }
    }

    .share-wrapper {
        position: sticky;
        top: 150px;
        z-index: 1;
        @media(max-width: 767px) { position: static; }
        .sharelinks {
            position: absolute;
            right: 0;
            z-index: 1;
            @media(max-width: 767px) { 
                position: static; 
                display: flex;
                gap: 20px;
                padding-top: 20px;
            }
            a, button {
                display: block;
                margin-bottom: 30px;
                transition: $transition;

                &:hover {
                    opacity: .8;
                }

                svg {
                    @media(max-width: 767px) { width: 25px; }
                }
            }

            button {
                padding: 0;
                background-color: transparent;
                border: none;
            }

            .social-icon {
                position: relative;
                svg {
                    position: relative;
                    z-index: 1;
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 36px;
                    height: 36px;
                    background-color: $ai-black;
                    border-radius: 50%;
                    top: 50%;
                    left: 50%;
                    transition: $transition;
                    transform: translate(-50%, -50%) scale(0);
                    transform-origin: center;
                }

                &:hover {
                    opacity: 1;
                    svg {
                        fill: $ai-white;
                    }

                    &::before {
                        transform: translate(-50%, -50%) scale(1);
                    }
                }
            }
        }
    }
}

.news-loader {
    text-align: center;
    padding-bottom: 4rem;
}