.content-image {
    p {
        font-size: 19px;
        line-height: 1.8;
        font-weight: 300;
        margin-bottom: 2rem;
    }

    .large-title {
        h2 {
            font-size: 74px;
            font-weight: 400;
            @media(max-width: 991px) { font-size: 50px; }
        }
    }

    .d-flex {
        flex-wrap: wrap;
    }

    img {
        width: 100%;
        height: auto;
    }

    .number {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 1.5rem;
    }

    h3 {
        font-size: 60px;
        font-weight: 600;
        margin-bottom: 2rem;
        @media(max-width: 991px) { font-size: 50px; }
    }

    &.full-width {
        overflow: hidden;
        .d-flex {
            align-items: center;
        }

        .image {
            img {
                @media(min-width: 768px) {
                    margin-left: -2.5rem;
                    width: 80%;
                }
                @media(max-width: 767px) { width: 100%; }
            }
            &.order-md-2 {
                text-align: right;
                img {
                    transition: $transition;
                    @media(min-width: 768px) {
                        margin-right: -2.5rem;
                        margin-left: auto;
                    }
                }
                &.animate {
                    img {
                        transform: rotate(7deg);
                        margin-top: 2rem;
                        margin-bottom: 2rem;
                    }
                }
            }

            &.left-image {
                img {
                    transition: $transition;
                }
                &.animate {
                    img {
                        transform: rotate(-7deg);
                        margin-top: 2rem;
                        margin-bottom: 2rem;
                    }
                }
            }
        }

        .text {
            padding-right: 4rem;
            padding-left: 15px;
            @media(max-width: 767px) { padding-left: 15px; }

            &.order-md-1 {
                padding-left: 4rem;
                padding-right: 15px;
                @media(max-width: 767px) { padding-left: 15px; }
            }
        }
    }
}