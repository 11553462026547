footer {
    background-color: $ai-black;
    >.container {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    p,li,a, h2, h3 {
        color: $ai-white;
        text-decoration: none;
    }

    ul {
        list-style: none;
        padding-left: 0;

        a {
            display: inline-block;
            margin-bottom: 13px;
            font-size: 15px;
            & > div > span{
                display: inline-flex;
                flex-wrap: wrap;
            }
            &:hover {
                color: $ai-white;
            }
        }

        .nav-item {
            a {
                position: relative;
                &::before {
                    content: '';
                    display: inline-block;
                    width: 0;
                    height: 1px;
                    bottom: -2px;
                    left: 0;
                    position: absolute;
                    background-color: $ai-white;
                    transition: $transition;
                }
                &:hover {
                    opacity: 1;
                    &::before {
                        width: 100%;
                    }
                }
            }

            &.address {
                a {
                    margin-bottom: 2px;
                    span {
                        position: relative;
                        &::before {
                            content: '';
                            display: inline-block;
                            width: 0;
                            height: 1px;
                            bottom: -2px;
                            left: 0;
                            position: absolute;
                            background-color: $ai-white;
                            transition: $transition;
                        }
                    }
                    &:hover {
                        &::before {
                            content: none;
                        }

                        span {
                            &::before {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        li {
            font-size: 1rem;
            @media(max-width: 991px) { font-size: 12px; }
            @media(max-width: 767px) { font-size: 14px; }
        }
    }

    h2 {
        font-size: 55px;
        font-weight: 600;
        @media(max-width: 991px) { font-size: 30px; }
    }

    h3 {
        font-weight: 400;
        font-size: 19px;
        margin-bottom: 20px;
    }

    .sitemap {
        margin-left: auto;
    }

    .pt-0 {
        justify-content: space-between;

        .links {
            gap: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media(max-width: 767px) { justify-content: flex-start; }
        }

        .btn {
            margin-right: 20px;
            @media(max-width: 767px) { margin-bottom: 15px; }
        }
    }

    .copyright {
        border-top: 1px solid rgba($ai-white, .4);
        background-color: $ai-black;
        padding-top: 30px;
        padding-bottom: 30px;

        p {
            margin-bottom: 0;
            text-align: right;
            font-size: 12px;
            @media(max-width: 767px) { text-align: center; }
        }

        a {
            position: relative;
            &::before {
                content: '';
                display: inline-block;
                width: 0;
                height: 1px;
                bottom: -2px;
                left: 0;
                position: absolute;
                background-color: $ai-white;
                transition: $transition;
            }
            &:hover {
                opacity: 1;
                color: $ai-white;
                &::before {
                    width: 100%;
                }
            }
        }
    }

    svg {
        fill: $ai-white;
    }

    .social-icon {
        width: 22px;
        position: relative;
        svg {
            width: 100%;
            fill: $ai-white;
            position: relative;
            z-index: 1;
        }

        &::before {
            content: '';
            position: absolute;
            width: 36px;
            height: 36px;
            background-color: $ai-white;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transition: $transition;
            transform: translate(-50%, -50%) scale(0);
            transform-origin: center;
        }

        &:hover {
            opacity: 1;
            svg {
                fill: $ai-black;
            }

            &::before {
                transform: translate(-50%, -50%) scale(1);
            }
        }
    }
}