@import 'lib/settings';
@import 'components/buttons.scss';
@import 'components/navigation.scss';
@import 'components/footer.scss';
@import 'components/sliders.scss';
@import 'components/banners.scss';
@import 'components/nextSection.scss';
@import 'components/videoModal.scss';
@import 'components/accordion.scss';
@import 'components/selectedProducts.scss';
@import 'components/contentImage.scss';
@import 'components/cta.scss';
@import 'components/textAnimations';

//Page specific styles
@import 'pages/home.scss';
@import 'pages/contact-us.scss';
@import 'pages/about.scss';
@import 'pages/services.scss';
@import 'pages/culture.scss';
@import 'pages/careers.scss';
@import 'pages/news.scss';
@import 'pages/our-work.scss';
@import 'pages/legal.scss';
@import 'pages/error-page.scss';

.component {
    .container {
        position: relative;
    }
}

.text-md-center {
    @media(min-width: 768px) {
        text-align: center;
    }
}

.close-btn{
    position: absolute;
    transition: transform .25s, opacity .25s;
    cursor: pointer;
    font-size: 3em;
    font-family: serif;
    line-height: 1;
}
.close-btn:before{
    content: "\d7";
    color: $ai-white;
}
.close-btn:hover{
    opacity: 1;
    transform: rotate(90deg);
}

.close {
    background-color: transparent;
    border: none;

}

.cookie-notice {
    background-color: $ai-black;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 111;

    p, a {
        color: $ai-white;
    } 
    
    .row {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }


    p {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        margin-bottom: 0;
    }

    .actions {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        @media(max-width: 767px) { 
            justify-content: flex-start; 
            padding-top: 20px;
        }

        button {
            display: inline-block;
        }
    }

    .close {
        position: static;
        font-size: 2em;
    }
}

.social-icon {
    transform-origin: center;
    &:hover {
        transform: scale(1.2);
    }
}

body{
}
:root{
    //Fix page scrolling from bottom to top on route change
    scroll-behavior: unset;
}

.loader {
    width: 48px;
    height: 48px;
    border: 3px solid $ai-dark-grey;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: $ai-black;
}

@keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.ytp-cued-thumbnail-overlay {
    display: none !important;
}

.mobile-placeholder {
    @media(min-width: 992px) {
        display: none;
    } 
}