.about-us {
    
    .navigation {
        background-color: transparent;
        border-bottom: 1px solid $ai-light-grey;

        &.scrolled {
            background-color: $ai-white;
        }
    }  

    .text-banner {
        padding-top: 116px;
        @media(max-width: 767px) { padding-top: 101px; }
        .container {
            &::before {
                @media(max-width: 767px) { 
                    height: 20%;
                }
            }

            .stats {
                .text {
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .stats {
            img {
                object-fit: contain;
                min-height: 87px;
            }
        }
    }

    .intro-content {
        h2 {
            font-weight: 400;
            font-size: 75px;
            margin-bottom: 2rem;
            @media(max-width: 991px) { font-size: 50px; }
        }

        .row {
            @media(min-width: 768px) {
                padding-bottom: 8.5rem;
                padding-top: 7rem;
            }
        }

        h4 {
            font-weight: 300;
            line-height: 1.8;
            font-size: 22px;
            margin-bottom: 4rem;
        }

        p {
            font-size: 18px;
            line-height: 1.8;
            font-weight: 300;
        }

        .text {
            strong {
                font-weight: 600;
                font-size: 18px;
            }

            >div {
                &:last-child {
                    p {
                        margin-bottom: 3.7rem;
                    }
                }
            }
        }

        .logos {
            strong {
                font-weight: 600;
            }

            .images {
                display: flex;
                gap: 45px;
                @media(max-width: 991px) { flex-wrap: wrap; }

                p {
                    font-size: 12px;
                    margin-bottom: 0;
                    font-weight: 300;
                }

                img {
                    height: 90px;
                    object-fit: contain;
                    object-position: center;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    max-width: 100%;
                    @media(max-width: 767px) {
                        margin-left: 0;
                        object-position: left;
                    }
                }
            }

            .logo {
                width: 15%;
                @media(max-width: 767px) { 
                    width: 40%;
                    text-align: left;
                }

                &:nth-child(1) {
                    img {
                        width: 66px;
                    }
                }

                &:nth-child(2) {
                    img {
                        width: 17px;
                    }
                }

                &:nth-child(3) {
                    img {
                        width: 88px;
                    }
                }

                &:nth-child(4) {
                    img {
                        width: 56px;
                    }
                }
            }
        }
    }

    .founders {
        background-color: $ai-black;
        color: $ai-white;
        padding-top: 4rem;
        overflow: hidden;

        p {
            font-size: 22px;
            font-weight: 300;
            line-height: 1.8;
        }

        h2 {
            font-size: 41px;
            font-weight: 600;
            margin-bottom: 1rem;
        }

        img {
            width: 100%;
            height: auto;
            transition: $transition;
        }

        .heading {
            margin-bottom: 1.5rem;
            strong {
                font-weight: 600;
                font-size: 22px;
            }

            p {
                margin-bottom: 0;
                font-size: 16px;
            }
        }

        .text {
            padding-left: 2rem;
            @media(max-width: 767px) { padding-top: 2rem; }
            &.order-md-1 {
                padding-right: 2rem;
                padding-left: 15px;
                @media(max-width: 767px) { padding-left: 25px; }
            }
            @media(max-width: 767px) { padding-left: 25px; }
        }

        .d-flex {
            align-items: center;
            flex-wrap: wrap;

            p {
                font-size: 19px;
            }
        }

        .image {
            img {
                @media(min-width: 768px) {
                    margin-left: -2.5rem;
                    width: 80%;
                }
            }
            &.order-md-2 {
                text-align: right;
                img {
                    transition: $transition;
                    @media(min-width: 768px) {
                        margin-right: -2.5rem;
                        margin-left: auto;
                    }
                }
            }
        }

        .right-text {
            .animate {
                img {
                    transform: rotate(7deg);
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }
            }

            .text {
                @media(min-width: 768px) {
                    padding-right: 7rem;
                }
            }
        }

        .left-text {
            padding-bottom: 8rem;
            .text {
                @media(min-width: 768px) {
                    padding-left: 7rem;
                }
            }

            .animate {
                img {
                    transform: rotate(-7deg);
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }
            }
        }
    }

    #the-team {
        @media(min-width: 768px) {
            padding-top: 4rem;

            .team-slider {
                padding-bottom: 0.5rem;
            }

            p {
                font-weight: 300;
            }

            h2 {
                font-size: 42px;
                line-height: 1.5;
                margin-bottom: 18px;
                font-weight: 600;
            }

            .slick-dots {
                display: none !important;
            }
        }

        .intro-content {
            padding-bottom: 2rem;
            p {
                font-size: 22px;
            }
        }
    }

    .heritage {
        @media(min-width: 768px) {
            padding-bottom: 4.5rem;
            padding-top: 3rem;
        }
        .logos {
            display: flex;
            gap: 45px;
            padding-top: 2.5rem;
            @media(max-width: 767px) { flex-wrap: wrap; }
            strong {
                font-weight: 600;
            }

            p {
                font-size: 14px;
                margin-bottom: 0;
                font-weight: 300;
            }

            img {
                height: 122px;
                object-fit: contain;
                object-position: center;
                margin-left: auto;
                margin-right: auto;
                display: block;
                width: 100%;
            }

            .image {
                width: 60%;
                margin-bottom: 1rem;
            }

            .logo {
                width: 30%;
                &:first-child {
                    .image {
                        width: 80%;
                    }
                }
                @media(max-width: 767px) { width: 100%; }

                a {
                    color: $ai-black;
                    display: inline-block;
                    font-weight: 600;
                    margin-top: 1rem;
                }
            }
        }
    }

    .cta {
        .content {
            padding-top: 12rem;
            @media(max-width: 767px) { padding-top: 2rem; }
        }
    } 
}