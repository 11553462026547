/* Bootstrap Overrides
----------------------------------------------- */
:root {
    //Fonts
    --bs-font-sans-serif: 'Montserrat', sans-serif;
    --bs-body-font-family: var($ai-fnt-poppins);
    //Links
    --bs-link-color: #85C137;
    --bs-link-hover-color: #ED174C;
  }
  .row{
    padding-top: 4rem;
    padding-bottom: 4rem;
    @media(max-width: 767px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .row{
      padding: 0;
    }
  }
  
  
  /* Colors
  ----------------------------------------------- */
  $ai-white: #FFFFFF;
  $ai-black: #000000;
  $ai-grey: #676767;
  $ai-dark-grey: #707070;
  $ai-light-grey: #CCCCCC;
  
  /**
    Backgrounds
   */

   // Variables
   $transition: all .5s;
  
  /**
    Text helpers
   */
  .ai-text-white{
    color: $ai-white;
  }

  main {
    min-height: 50vh;
  }
  
  /* Fonts
  ----------------------------------------------- */
  $ai-fnt-tit: 'Titillium Web', sans-serif;
  
  //Font Styles
  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }
  body {
    font-family: $ai-fnt-tit;
    scroll-behavior: smooth;
  }
  h1{}
  h2{
    font-size: 2.375rem;
    font-weight: 400;
    @media(max-width: 991px) { font-size: 50px; }
  }
  h3{}
  h4{}
  h5{}
  h6{}
  a{}
  p, li{
    font-size: 22px;
    font-weight: 300;
  }
  
  //Text Helpers
  a {
    transition: $transition;
    &:hover {
        opacity: .6;
    }
  }

  //Font Family
  
  
  
  //Alignment
  .ai-text-center{
    text-align: center;
  }

  .ml-auto {
    margin-left: auto;
  }

  .mr-auto {
    margin-right: auto;
  }

  .hide {
    display: none;
  }
  
  /* Spacings
  ----------------------------------------------- */
  /**
    Paddings
   */
  .ai-{
    &p{
      //padding bottom (ai-pt-x)
      &t-0{padding-top: 0;}
      &t-1{padding-top: 1rem;}
      &t-2{padding-top: 2rem;}
      &t-3{padding-top: 3rem;}
      &t-4{padding-top: 4rem;}
      &t-5{padding-top: 5rem;}
      &t-6{padding-top: 6rem;}
  
      //padding right (ai-pl-x)
      &r-0{padding-right: 0;}
      &r-1{padding-right: 1rem;}
      &r-2{padding-right: 2rem;}
      &r-3{padding-right: 3rem;}
      &r-4{padding-right: 4rem;}
      &r-5{padding-right: 5rem;}
      &r-6{padding-right: 6rem;}
  
      //padding bottom (ai-pb-x)
      &b-0{padding-bottom: 0;}
      &b-1{padding-bottom: 1rem;}
      &b-2{padding-bottom: 2rem;}
      &b-3{padding-bottom: 3rem;}
      &b-4{padding-bottom: 4rem;}
      &b-5{padding-bottom: 5rem;}
      &b-6{padding-bottom: 6rem;}
  
      //padding left (ai-pl-x)
      &l-0{padding-left: 0;}
      &l-1{padding-left: 1rem;}
      &l-2{padding-left: 2rem;}
      &l-3{padding-left: 3rem;}
      &l-4{padding-left: 4rem;}
      &l-5{padding-left: 5rem;}
      &l-6{padding-left: 6rem;}
  
      //padding horizontal (ai-px-x)
      &x-0{padding-left: 0; padding-right: 0;}
      &x-1{padding-left: 1rem; padding-right: 1rem;}
      &x-2{padding-left: 2rem; padding-right: 2rem;}
      &x-3{padding-left: 3rem; padding-right: 3rem;}
      &x-4{padding-left: 4rem; padding-right: 4rem;}
      &x-5{padding-left: 5rem; padding-right: 5rem;}
      &x-6{padding-left: 6rem; padding-right: 6rem;}
  
      //padding vertical (ai-py-x)
      &y-0{padding-bottom: 0; padding-top: 0;}
      &y-1{padding-bottom: 1rem; padding-top: 1rem;}
      &y-2{padding-bottom: 2rem; padding-top: 2rem;}
      &y-3{padding-bottom: 3rem; padding-top: 3rem;}
      &y-4{padding-bottom: 4rem; padding-top: 4rem;}
      &y-5{padding-bottom: 5rem; padding-top: 5rem;}
      &y-6{padding-bottom: 6rem; padding-top: 6rem;}
    }
  
    &m{
      //margin top (ai-mt-x)
      &t-0{margin-top: 0;}
      &t-1{margin-top: 1rem;}
      &t-2{margin-top: 2rem;}
      &t-3{margin-top: 3rem;}
      &t-4{margin-top: 4rem;}
      &t-5{margin-top: 5rem;}
      &t-6{margin-top: 6rem;}
  
      //margin right (ai-ml-x)
      &r-0{margin-right: 0;}
      &r-1{margin-right: 1rem;}
      &r-2{margin-right: 2rem;}
      &r-3{margin-right: 3rem;}
      &r-4{margin-right: 4rem;}
      &r-5{margin-right: 5rem;}
      &r-6{margin-right: 6rem;}
  
      //margin bottom (ai-mb-x)
      &b-0{margin-bottom: 0;}
      &b-1{margin-bottom: 1rem;}
      &b-2{margin-bottom: 2rem;}
      &b-3{margin-bottom: 3rem;}
      &b-4{margin-bottom: 4rem;}
      &b-5{margin-bottom: 5rem;}
      &b-6{margin-bottom: 6rem;}
  
      //margin left (ai-ml-x)
      &l-0{margin-left: 0;}
      &l-1{margin-left: 1rem;}
      &l-2{margin-left: 2rem;}
      &l-3{margin-left: 3rem;}
      &l-4{margin-left: 4rem;}
      &l-5{margin-left: 5rem;}
      &l-6{margin-left: 6rem;}
  
      //margin horizontal (ai-mx-x)
      &x-1{margin-left: 0; margin-right: 0;}
      &x-1{margin-left: 1rem; margin-right: 1rem;}
      &x-2{margin-left: 2rem; margin-right: 2rem;}
      &x-3{margin-left: 3rem; margin-right: 3rem;}
      &x-4{margin-left: 4rem; margin-right: 4rem;}
      &x-5{margin-left: 5rem; margin-right: 5rem;}
      &x-6{margin-left: 6rem; margin-right: 6rem;}
  
      //margin vertical (ai-my-x)
      &y-0{margin-bottom: 0; margin-top: 0;}
      &y-1{margin-bottom: 1rem; margin-top: 1rem;}
      &y-2{margin-bottom: 2rem; margin-top: 2rem;}
      &y-3{margin-bottom: 3rem; margin-top: 3rem;}
      &y-4{margin-bottom: 4rem; margin-top: 4rem;}
      &y-5{margin-bottom: 5rem; margin-top: 5rem;}
      &y-6{margin-bottom: 6rem; margin-top: 6rem;}
    }
  }

.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    @media(min-width: 1280px) { max-width: 1240px; }
    @media(min-width: 1400px) { max-width: 1240px; }
}
  
  
  /* Mixins
  ----------------------------------------------- */