.contact-us {
    .contact-banner {
        padding-top: 116px;
        @media(max-width: 767px) { padding-top: 140px; }
    }

    .navigation {
        background-color: transparent;
        border-bottom: 1px solid $ai-light-grey;

        svg {
            fill: $ai-white;
        }

        .btn {
            color: $ai-black;

            &::after {
                background-color: $ai-white;
            }

            &:hover {
                color: $ai-white;
            }
        }

        .navigation__toggler span {
            background-color: $ai-white;
        }

        a {
            &::before {
                background-color: $ai-white;
            }
            &:hover {
                svg {
                    fill: $ai-black;
                }
            }
        }
        &.scrolled {
            background-color: $ai-white;

            svg {
                fill: $ai-black;
            }

            .btn {
                color: $ai-white;

                &::after {
                    background-color: $ai-black;
                }

                &:hover {
                    color: $ai-black;
                }
            }

            .navigation__toggler span {
                background-color: $ai-black;
            }

            a {
                &::before {
                    background-color: $ai-black;
                }
                &:hover {
                    svg {
                        fill: $ai-white;
                    }
                }
            }
        }
    }
}