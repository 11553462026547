.our-work {
    margin-top: 0;
    position: relative;
    padding-bottom: 4rem;
    background-image: url('../../assets/images/The-Odd-Number-Home-Pattern-Light.svg');
    background-size: contain;
    overflow: hidden;

    .content {
        padding-top: 145px;

        >.row {
            position: relative;
            z-index: 1;
        }
    }

    .navigation {
        background-color: transparent;
        border-bottom: 1px solid $ai-light-grey;

        &.scrolled {
            background-color: $ai-white;
        }
    }
    .work-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -11;
        background-size: cover;
        opacity: 0;
        object-fit: cover;
        background-repeat: no-repeat;
        background-position: right;
        background-color: #000;
        object-position: right;
    }

    iframe.work-image {
        transform: scale(1.1);
        @media(max-width: 991px) { display: none; }
    }

    .row {
        @media(max-width: 767px) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }

    h1 {
        font-weight: 400;
        font-size: 74px;
        @media(max-width: 991px) { font-size: 60px; }
        @media(max-width: 767px) { font-size: 40px; }
    }

    h2 {
        margin-bottom: 0;
        position: relative;
        z-index: 1;
        @media(max-width: 767px) { font-size: 30px; }
    }

    .work-item {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($ai-black, 0.4);
            z-index: 0;
            transition: $transition;
            opacity: 0;
        }

        &:hover {
            &::before {
                opacity: 1;
            }
        }

        a {
            color: $ai-black;
            text-decoration: none;
            display: block;
            padding: 1rem 0;
            position: relative;
            z-index: 1;
        
            h2 {
                transition: $transition;
                width: fit-content;
            }

            &:hover {
                color: $ai-white;
                opacity: 1;
                h2 {
                    transform: scale(1.3);
                    transform-origin: left;
                }

                +.work-image, +.work-image + img {
                    opacity: 1;
                }
            }
        }

        &:hover {
            a {
                color: $ai-white;
                opacity: 1;

                h2 {
                    opacity: 1 !important;
                }

                +.work-image {
                    opacity: 1;
                }
            }
            ~.work-item {
                a {
                    opacity: .6;
                }
            }

            ~.row {
                color: $ai-white;
            }
        }
    }

    &.hover {
        a, h1 {
            color: $ai-white;
        }

        h2 {
            opacity: .6;
        }
    }

    iframe {
        &.work-image {
            transform: scale(1.5);
        }
    }
}

.single-work {
    padding-top: 116px;
    @media(max-width: 767px) { padding-top: 140px; }
    .text-banner {
        h2 {
            font-size: 20px;
            margin-bottom: 2.5rem;
        }

        img {
            margin-bottom: 2.5rem;
            @media(min-width: 992px) { width: 32%; }
        }
    }

    .work-intro {
        p {
         font-weight: 300;
         margin-bottom: 0;
        }
 
        strong {
            font-size: 20px;
            font-weight: 600;
        }

        .container {
            border-top: 1px solid $ai-dark-grey;
        }
     }

    .logos {
        display: flex;
        gap: 45px;
        padding-top: 2.5rem;
        @media(max-width: 767px) { flex-wrap: wrap; }
        strong {
            font-weight: 600;
        }

        p {
            font-size: 14px;
            margin-bottom: 0;
            font-weight: 300;
        }

        img {
            height: 85px;
            object-fit: contain;
            object-position: center;
            margin-left: auto;
            margin-right: auto;
            display: block;
            width: 100%;
        }

        .image {
            width: 60%;
            margin-bottom: 1rem;
            @media(max-width: 767px) { width: 100%; }
        }

        .logo {
            width: 30%;
            @media(max-width: 767px) { 
                width: 40%; 
                text-align: center;
            }

            &:first-child {
                .image {
                    width: 50%;
                    @media(max-width: 767px) { width: 100%; }
                }
            }

            a {
                color: $ai-black;
                display: inline-block;
                font-weight: 600;
                margin-top: 1rem;
            }
        }
    }

    p {
        font-weight: 300;
    }

    .observations, #concept {
        h2 {
            margin-bottom: 2rem;
        }

        p {
            margin-bottom: 2.5rem;
            font-size: 20px;
        }

        .results {
            .row {
                justify-content: space-between;
            }

            .counter {
                font-weight: 700;
                font-size: 76px;
                margin-bottom: 0 !important;
            }

            h2 {
                font-weight: 400;
                font-size: 42px;
            }
        }
    }

    .observations {
        @media(min-width: 991px) {
            padding-bottom: 2rem;
        }
    }

    #concept {
        @media(min-width: 992px) {
            padding-top: 4rem;
            padding-bottom: 2rem;
        }
    }

    .work-slider {
        background-color: $ai-black;
        color: $ai-white;
        @media(min-width: 991px) { padding-top: 4rem; }

        p {
            font-weight: 300;
            font-size: 20px;
            line-height: 2;
        }

        strong {
            font-weight: 600;
            font-size: 19px;
        }

        .slick-slide {
            img {
                width: 100%;
            }
        }

        .single-slider-wrapper {
            position: relative;

            .container {
                &:last-child {
                    padding-bottom: 10rem;
                }
            }

            .single-slider__image {
                width: 100%;
            }
        }

        .slick-arrow {
            bottom: initial;
            top: -70px;

            &.slick-prev {
                left: 10px;
            }

            &.slick-next {
                left: 60px;
            }
        }
    }

    .image-banner {
        overflow: hidden;
        img {
            width: 100%;
            height: auto;
        }

        iframe {
            pointer-events: none;
            transform-origin: center;
            transform: scale(1.5);
            pointer-events: none;
            width: 100%;
            aspect-ratio: 16/9;
            @media(max-width: 991px) { display: none; }
        }
    }

    .brand-cta {
        text-decoration: none;
        position: relative;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            z-index: 0;
            height: 180%;
            transform: translateY(-50%);
            top: 50%;
            opacity: 0;
            transition: all .3s;
            pointer-events: none;
        }
        &:hover {
            opacity: 1;
            text-decoration: none;

            iframe {
                opacity: 1;
            }

            h2, h5 {
                color: $ai-white;
                position: relative;
            }
        }
        .btn {
            background-color: $ai-black;
            color: $ai-white;
            &:hover {
                background-color: $ai-white;
                color: $ai-black;
            }
        }

        h2 {
            font-size: 42px;
            font-weight: 600;
        }

        h5 {
            font-weight: 600;
        }
    }

    .sharelinks {
        display: flex;
        gap: 15px;
        padding-top: 10px;
        @media(max-width: 767px) { padding-bottom: 20px; }

        svg {
            width: 25px;
        }

        a, button {
            svg {
                position: relative;
                z-index: 1;
            }

            &::before {
                content: '';
                position: absolute;
                width: 36px;
                height: 36px;
                background-color: $ai-black;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transition: $transition;
                transform: translate(-50%, -50%) scale(0);
                transform-origin: center;
            }

            &:hover {
                opacity: 1;
                svg {
                    fill: $ai-white;
                }

                &::before {
                    transform: translate(-50%, -50%) scale(1);
                }
            }
        }

        button {
            background-color: transparent;
            border: none;
            padding: 0;
        }
    }
}