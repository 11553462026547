.selected-product {
    position: relative;
    padding-bottom: 7rem;
    padding-top: 7rem;
    overflow: hidden;
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: $ai-black;
    @media(max-width: 767px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -25%;
        width: 150%;
        height: 100%;
        background-color: $ai-white;
        z-index: 1;
        transition: $transition;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($ai-black, 0.4);
        z-index: 1;
        transition: $transition;
        opacity: 0;
    }

    &:hover {
        color: $ai-white;
        opacity: 1;
        &::before {
            transform: translate(0, -150%) rotate(10deg);
        }
        &::after {
            opacity: 1;
        }

        h3, h4, p {
            color: $ai-white;
        }

        .btn {
            //background-color: $ai-white;
            color: $ai-black;
            //border: 1px solid $ai-white;
            &:after{
                background-color: $ai-white
            }

            &:hover {
                background-color: $ai-black;
                color: $ai-white;
                //border: 1px solid $ai-black;
            }
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        z-index: -1;
        &.iframe {
            height: 180%;
            transform: translateY(-50%);
            top: 50%;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }

    .container {
        position: relative;
        z-index: 2;
    }

    h3 {
        font-size: 40px;
        font-weight: 600;
    }

    h4 {
        font-size: 20px;
        font-weight: 600;
    }

    p {
        font-size: 30px;
    }
}