#our-clients {
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-attachment: fixed;
    background-size: cover;
    @media(max-width: 767px) {
        background-size: initial !important;
    }

    h2 {
        color: $ai-white;
        font-weight: 600;
    }
    .tab-links {
        border-bottom: 1px solid $ai-dark-grey;
        button {
            background-color: transparent;
            color: $ai-black;
            border: none;
            padding: 1.5rem 0 1.5rem 0;
            margin-right: 2rem;
            font-size: 12px;
            font-weight: 600;
            opacity: .5;
            &.active {
                opacity: 1;
                border-bottom: 2px solid $ai-black;
            }
        }
    }

    .logos {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
        gap: 6%;

        img {
            width: 100%;
            height: auto;
        }

        >div {
            width: 12%;
            @media(max-width: 991px) { width: 20%; }
            @media(max-width: 767px) { width: 29%; }
        }
    }

    .link {
        text-align: center;
    }

    p {
        font-weight: 300;
        line-height: 2;
        margin-bottom: 2.2rem;
    }

    h2 {
        font-size: 42px;
        margin-bottom: 1.5rem;
    }
}

.services {

    .navigation {
        background-color: transparent;
        border-bottom: 1px solid $ai-light-grey;
        &.scrolled {
            background-color: $ai-white;
        }
    }

    .text-banner {
        padding-top: 116px;
        @media(max-width: 767px) { padding-top: 101px; }
        .container {
            &::before {
                height: 51%;
                @media(max-width: 767px) { 
                    height: 27%;
                }
            }
        }
    }

    .content-image {
        .row {
            align-items: center;
        }

        img {
            width: 70%;
        }
    }

    #our-services {
        padding-bottom: 8.5rem;
        padding-top: 4rem;
        overflow: hidden;
        @media(max-width: 991px) {
            padding-bottom: 4rem;
        }
        @media(max-width: 767px) {
            padding-bottom: 2rem;
        }

        & > .container{
            p{
                margin-bottom: 0px;
            }
        }

        .ml-auto {
            h2 {
                font-size: 74px;
                font-weight: 400;
                margin-bottom: 20px;
            }

            p {
                font-weight: 300;
                line-height: 1.7;
                font-size: 21px;
            }
        }

        .content-image {
            padding-bottom: 2rem;
            img {
                width: 100%;
            }

            .text.order-2 {
                padding-left: 7rem;
                @media(max-width: 767px) { padding-left: 15px; }

                &.order-md-1 {
                    padding-left: 15px;
                    padding-right: 12rem;
                    @media(max-width: 767px) { padding-right: 15px; }
                }
            }

            &.full-width {
                .text.order-2 {
                    &.order-md-1 {
                        padding-left: 6.5rem;
                        padding-right: 7rem;
                        @media(max-width: 767px) { 
                            padding-left: 15px; 
                            padding-right: 15px;
                        }
                    }
                }
            }

            .text {
                @media(max-width: 767px) { padding-top: 50px; }
            }
        }
    }

    .accordion-wrapper {
        padding-top: 5rem;
        padding-bottom: 9rem;

        .next-section {
            bottom: -9rem;
        }

        h2 {
            font-size: 42px;
            font-weight: 600;
            margin-bottom: 2rem;
            line-height: 1.5;
        }

        p {
            margin-bottom: 2rem;
        }

        .accordion {
            .answer {
                p {
                    font-size: 15px;
                }
            }
        }
    }

    .cta {
        .heading {
            padding-right: 15px;
            @media(min-width: 1200px) {
                padding-right: 15rem;
            }
        }
    }
}