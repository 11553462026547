.video-modal {
    img {
        width: 100%;
        height: auto;

    }
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: fit-content;
        height: 80%;
        //background-color: white;
        z-index: 11;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        overflow: visible;
    }

    .thumbnail-wrapper {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($ai-black, .5);
            z-index: 1;
        }
        
        .play-icon {
            position: absolute;
            width: 100px;
            height: 100px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: $transition;
            outline: 0px solid rgba($ai-black, .2);
            border-radius: 50%;
            z-index: 1;
        }

        &:hover {
            cursor: pointer;
            .play-icon {
                outline: 20px solid rgba($ai-black, .2);
                background-color: rgba($ai-black, .2);
            }
        }

    }
  
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($ai-black, .9);
        opacity: 0.5;
        z-index: 11;
        
    }
  
    .modal-content {
        position: relative;
        z-index: 1;
        background-color: transparent;
        width: fit-content;
    }

    iframe {
        aspect-ratio: 16/9;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media(max-width: 767px) {
            max-height: 350px;
            max-width: 90vw;
        }
        @media(max-width: 500px) {
            max-height: 230px;
        }
    }

    .short-video {
        overflow: hidden;
         iframe, .mobile-placeholder {
            position: static;
            transform: scale(1.3);
            width: 100%;
            object-fit: cover;
            object-position: center;
        } 
        
        iframe {
            @media(max-width: 991px) { display: none; }
        }
    } 
  
    .close {
        position: fixed;
        z-index: 11;
        top: 10px;
        right: 10px;
    }
}