.btn {
    border-radius: 24px;
    font-size: 14px;
    opacity: 1 !important;
    padding: 8px 20px;
    background: none !important;
    position: relative;
    overflow: hidden;
    //transition: border-radius 1s ;
    border: none;
    z-index: 2;
    &:active{
        &:after{
            transform: scaleY(1);
        }
    }
    &:after{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        transition: transform .5s .3s ease, width .3s 0s ease;
        transform-origin: bottom;
        z-index: -1;
    }
    &:hover{
        //border-radius: 0;
        &:after{
            width: calc(100% - 30px);
            transform: scaleY(0.05);
            transition: transform .5s 0s ease, width .3s .3s ease;
        }
    }
    &__light {
        color: $ai-black;
        //border: 1px solid $ai-white;
        &:after{
            background-color: $ai-white;
        }
        &:hover {
            &:after{
                background-color: $ai-white;
            }
            background-color: $ai-black;
            color: $ai-white;
            //border: 1px solid $ai-white;
        }
    }
    &__dark {
        color: $ai-white;
        //border: 1px solid $ai-black;
        &:after{
            background-color: $ai-black;
        }
        &:hover {
            background-color: $ai-white;
            color: $ai-black;
            //border: 1px solid $ai-black;
        }
    }
}