.cta {
    color: $ai-white;
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
    display: flex;
    align-items: center;
    min-height: 590px;
    background-attachment: fixed;
    background-size: cover;
    @media(max-width: 767px) {
        background-attachment: initial;
        background-size: initial !important;
    }

    .row {
        justify-content: space-between;
    }

    p {
        line-height: 1.8;
        font-weight: 300;
        margin-bottom: 1.8rem;
    }

    h2 {
        font-size: 72px;
        font-weight: 400;
        @media(max-width: 991px) { font-size: 50px; }
    }

    .heading {
        @media(min-width: 768px) { padding-right: 0; }
    }
}