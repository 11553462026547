.navigation {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
    transition: all .2s;
    background: $ai-white;
    
    &.scrolled {
        border-bottom: 1px solid $ai-light-grey;
    }
    &__bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 8px;
        padding-bottom: 8px;
        @media(max-width: 767px) {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }

    &__toggler {
        background-color: transparent;
        border: none;
        width: fit-content;
        span {
            display: block;
            background-color: $ai-black;
            height: 3px;
            width: 30px;
            position: relative;
            margin-bottom: 5px;
            transition: $transition;
            overflow: hidden;
            &:last-child { margin-bottom: 0;
            }
        }
        &:hover{
            span{
                margin-bottom: 6px;
                //transform-origin: 0 50% 0;
                animation: spanElement 1s ease;
                animation-delay: calc(var(--animation-delay) * 90ms);
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        @keyframes spanElement {
            0%{
                transform: scaleX(1);
                transform-origin: right;
            }
            50%{
                transform: scaleX(0);
                transform-origin: right;
            }
            50.0001%{
                transform: scaleX(0);
                transform-origin: left;
            }
            100%{
                transform: scaleX(1);
                transform-origin: left;
            }
        }


        &.open {
            transition: transform 0.25s, opacity 0.25s;
            span {
                background-color: $ai-white;
                &:nth-child(1) {
                    transform: rotate(45deg) translate(5.5px, 5.5px);
                }
                &:nth-child(2) {
                    transform: rotate(-45deg);
                }
                &:last-child { display: none; }
            }

            &:hover {
                transform: rotate(90deg);
                span{
                    animation: none !important;
                }
            }
        }
    }

    &__items, &__socials {
        display: flex;
        align-items: center;
        transition: $transition;
    }

    &__socials {
        gap: 20px;
        margin-right: 20px;
        @media(max-width: 767px) {
            display: none !important;
        }
    }

    .btn {
        margin-right: 30px;
        transition: $transition;
        font-size: 12px;
    }

    .social-icon {
        width: 22px;
        position: relative;
        svg {
            width: 100%;
            position: relative;
            z-index: 1;
        }

        &::before {
            content: '';
            position: absolute;
            width: 36px;
            height: 36px;
            background-color: $ai-black;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transition: $transition;
            transform: translate(-50%, -50%) scale(0);
            transform-origin: center;
        }

        &:hover {
            opacity: 1;
            svg {
                fill: $ai-white;
            }

            &::before {
                transform: translate(-50%, -50%) scale(1);
            }
        }
    }

    .logo {
        width: 96px;
        @media(max-width: 767px) { width: 60px; }
        svg {
            width: 100%;
        }
    }

    &__menu-wrapper {
        position: fixed;
        transform: translateY(-150%);
        transition: $transition;
        top: 116px;
        width: 100%;
        height: calc(100vh - 116px);
        background-color: $ai-black;
        @media(max-width: 767px) { 
            transform: translateY(-200%); 
            top: 87.47px;
            height: calc(100vh - 87.47px);
            overflow: scroll;
        }

        &.open {
            transform: translateY(0);
        }

        h2 {
            font-weight: 700;
            color: $ai-white;
            font-size: 110px;
            margin-bottom: 3.5rem !important;
            transform: translateY(-150px);
            opacity: 0;
            transition: opacity 1s ease, transform 1s ease;
            transition-delay: .2s;
            margin-left: -7px;
            @media(max-width: 767px) { font-size: 50px; }
        }

        .row {
            padding-top: 0;
        }

        .contacts {
            display: flex;
            align-items: flex-end;

            p {
                color: $ai-white;
                font-size: 18px;
                a {
                    color: $ai-white;
                    font-weight: 300;
                    text-decoration: none;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 1px;
                        background-color: $ai-white;
                        bottom: -5px;
                        left: 0;
                        transition: $transition;
                    }

                    &:hover {
                        opacity: 1;
                        &::before {
                            width: 100%;
                        }
                    }
                }
            }

            .links {
                display: flex;
                align-items: center;
                padding-bottom: 30px;
                gap: 20px;
            }
        }
    }

    &.nav-open {
        background: $ai-black !important;
        border-bottom: none !important;
        h2{
            transform: translateY(0);
            opacity: 1;
        }
        .navigation__socials {
            opacity: 0;
            pointer-events: none;
        }

        svg {
            fill: $ai-white !important;
        }

        .navigation__items {
            .btn {
                opacity: 0 !important;
                pointer-events: none;
            }
        }

        .navigation__toggler {
            span {
                background-color: $ai-white !important;
            }
        }
        .navigation__nav-items {
            li {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    &__nav-items {
        list-style: none;
        padding-left: 0;
        li{
            transform: translateY(-150px);
            opacity: 0;
            transition: transform 1s ease, opacity 1s ease;
            transition-delay: calc((var(--animation-delay) * -45ms) + 300ms) ;
        }
        a {
            font-weight: 600;
            color: $ai-white;
            text-decoration: none;
            opacity: .6;
            font-size: 22px;
            transition: $transition;
            padding: 8px;
            display: inline-block;
            transform-origin: left;
            padding-left: 0;

            &:hover {
                opacity: 1;
                transform: scale(1.5);
            }
        }

        li {
            &.active {
                a {
                    opacity: 1;
                    transform: scale(1.5);
                }
            }
        }

        li &:last-child {
            a {
                padding-bottom: 0;
            }
        }
    }
}

#home-nav {
    .navigation {
        &__toggler {
            span {
                background-color: $ai-white;
            }
        }
    }
}