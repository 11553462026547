span.ai-reveal-box{
  display: inline-block;
  height: fit-content;
  overflow: hidden;
  span{
    display: block;
  }
}

h2{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

body{
  scroll-behavior: unset !important;
}
p{
  overflow-wrap: break-word;
}

.ai-split-text-container{
  display: block;
  padding: 0 !important;
  position: relative;
  width: 100%;
}
.ai-split-line{
  display: block;
  overflow: hidden;
  &.test-lines{
    display: block;
    height: 0;
    margin: 0 !important;
    overflow: scroll;
    padding: 0 !important;
    visibility: hidden;
    white-space: nowrap;
    width: auto;
  }
  .line{
    display: block;
    white-space: nowrap;
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    transition: transform 1s ease, opacity 1s ease;
    transition-delay: calc(var(--animation-delay) * 90ms);
  }
  &.animate{
    .line{
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}