.accordion-wrapper {
    background-color: $ai-black;
    color: $ai-white;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .row {
        justify-content: space-between;
    }

    p {
        font-weight: 300;
        line-height: 2;
    }

    .col-lg-5 {
        padding-right: 7.5rem;
        @media(max-width: 991px) { padding-right: 15px; }

        .btn {
            @media(max-width: 767px) { margin-bottom: 50px; }
        }
    }

    .accordion {

        &:last-child {
            border-bottom: 1px solid $ai-white;
        }
    
        .question {
            padding: 30px 25px 30px 0;
            color: $ai-white;
            margin-bottom: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: all .3s;
            border-top: 1px solid $ai-white;

            h4 {
                margin-bottom: 0;
                color: $ai-white;
                font-size: 20px;
                font-weight: 600;
                @media(max-width: 991px) { font-size: 16px; }
            }

            .icon {
                display: inline-block;
                width: 24px;
                height: 15px;
                background-size: 100%;
                transition: $transition;
                background-image: url('../../assets/images/icons/The-Odd-Number-Dropdown.svg');
                background-repeat: no-repeat;
            }

            &.active, &:hover {
                cursor: pointer;
            }

            &.active {
                .icon {
                    transform: rotate(180deg);
                }
            }
        }

        .answer {
            padding: 30px 48px;
            padding-top: 0;
            &.hide {
                display: none;
            }

            p, li {
                margin-bottom: 0;
                font-size: 17px;
                color: $ai-white;
                @media(max-width: 991px) { font-size: 14px; }
            }
        }
    }

    .next-section {
        bottom: -3rem;
    }
}

.career-accordion {
    .job {
        display: flex;
        justify-content: space-between;
        @media(max-width: 767px) { flex-wrap: wrap; }
    }

    .details {
        width: 50%;
        @media(max-width: 767px) { width: 100%; }
    }

    .description {
        width: 35%;
        @media(max-width: 767px) { 
            width: 100%; 
            padding-bottom: 3rem;
        }

        p {
            &:last-child {
                padding-left: 20px;
            }
        }
    }

    strong {
        font-weight: 600;
        display: block;
        margin-bottom: 1rem;
        font-size: 15px;
    }

    .answer {
        .job {
            p, li {
                font-size: 14px;
            }

            .btn {
                margin-top: 32px;
                margin-left: 20px;
            }
        }
    }

    ul {
        list-style: none;
        padding-left: 2.5rem;

        li {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                left: -40px;
                top: 13px; 
                height: 1px;
                width: 15px;  
                background-color: $ai-white;       
            }
        }
    }

    .responsibilities {
        margin-bottom: 2.5rem;
    }

    .details {
        .responsibilities, .requirements {
            p {
                margin-bottom: 1.5rem;
            }

            >div {
                p {
                    padding-left: 20px;

                    strong {
                        margin-left: -20px;
                    }
                }

                ul {
                    padding-left: 75px;
                }
            }
        }
    }
}