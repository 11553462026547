.legal {
    
    .navigation {
        background-color: transparent;
        border-bottom: 1px solid $ai-light-grey;
        &.scrolled {
            background-color: $ai-white;
        }
    }
    .text-banner {
        padding-top: 116px;
        @media(max-width: 767px) { padding-top: 140px; }
        .container {
            &::before {
                height: 51%;
                @media(max-width: 767px) { 
                    height: 55%;
                }
            }
        }
    }

    .post-content {
        h3 {
            font-weight: 600;
            font-size: 40px;
            margin-bottom: 1.5rem;
        }

        p {
            font-size: 18px;
            font-weight: 300;
            line-height: 2;
        }
    }
}