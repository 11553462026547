.team-slider {
    overflow: hidden;
    position: relative;
    padding: 3rem 0;
    padding-top: 5rem;
    @media(min-width: 992px) {
        width: 92.5%;
        margin-left: auto;
    }
    @media(max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
    }


    img {
        width: 100%;
    }

    .slick-track {
        display: flex;
        gap: 20px;
    }

    &__item {
        position: relative;
        overflow: hidden;

        &:hover {
            .team-slider__content {
                transform: translateY(0);
            }
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding: 20px;
        background-color: $ai-black;
        opacity: 1;
        transition: $transition;
        transform: translateY(150%);

        h4, p {
            color: $ai-white;
        }

        h4 {
            font-size: 20px;
            font-weight: 600;
        }

        strong {
            display: block;
            margin-bottom: 2rem;
            font-weight: 300;
        }

        p {
            font-size: 16px;
            padding-right: 5px;
            &:last-child {
                line-height: 2.1;
            }
        }
    }

    &__bio {
        font-weight: 300;
        line-height: 2.4;
    }

    &__position {
        margin-bottom: 2rem;
    }

    .slick-arrow {
        width: 30px;
        position: absolute;
        top: 0;
        transition: $transition;
        cursor: pointer;
        overflow: hidden;
        img{
            transition: $transition;
            &.slick-arrow-transition{
                position: absolute;
                top: 0;
                left: 0;
                transform: translateX(40px);
            }
        }
        &.slick-next {
            left: 4.5%;
            img{
                transition: $transition;
                &.slick-arrow-transition{
                    transform: translateX(-40px);
                }
            }
            @media(max-width: 991px) { left: 10%; }
            @media(max-width: 767px) { left: 15%; }

            &:hover {
                img{
                    transform: translateX(40px);
                    &.slick-arrow-transition{
                        transform: translateX(0);
                    }
                }
            }
        }

        &.slick-prev {
            left: 0;
            @media(max-width: 767px) { left: 10px; }
            &:hover {
                img{
                    transform: translateX(-40px);
                    &.slick-arrow-transition{
                        transform: translateX(0);
                    }
                }
            }
        }
    }

    .slick-dots {
        bottom: 10%;
        text-align: left;
        max-width: 1320px;
        left: 50%;
        transform: translateX(-50%);
        padding-left: 15px;

        li {
            margin: 0;
            width: 15px;
            height: 15px;
            button {
                &::before {
                    color: $ai-white;
                }
            }
            &.slick-active {
                button {
                    &::before {
                        color: $ai-white;
                        font-size: 10px;
                    }
                }
            }
        }
    }
}

.single-slider-wrapper {
    padding-top: 6rem;
    overflow: hidden;
    background-attachment: fixed;
    background-size: cover;
    @media(max-width: 767px) {
        background-size: 250% 100%;
        background-attachment: initial;
    }
    .slick-track {
        display: flex;
        gap: 20px;
        transition: none !important;
    }
    .single-slider {
        position: relative;
        &__image {
            width: 22%;
            min-height: 550px;
            margin: 0 auto;
            @media(max-width: 767px) { min-height: initial; }
            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                transition: transform .5s ease-in-out;
                transform: translate3d(0,0,0) scale(0.01);
                image-rendering: optimizeSpeed;
            }
        }
        &__content {
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            div{
                overflow: hidden;
            }
            .btn.btn__dark{
                &:hover{
                    color: $ai-white;
                    &:after{
                        background-color: $ai-white;
                    }
                }
            }
        }

        h2, h3 {
            color: $ai-white;
            text-align: center;
            transform: translateY(100%);
            transition: transform .5s ease-in-out;
        }

        h2 {
            font-weight: 400;
            font-size: 60px;
            justify-content: center;
            @media(max-width: 991px) { font-size: 30px !important; }
            @media(max-width: 767px) { font-size: 18px !important; }
        }

        h3 {
            font-weight: 700;
            font-size: 150px;
            letter-spacing: 30px;
            @media(max-width: 991px) {
                font-size: 50px;
                letter-spacing: 20px;
            }
            @media(max-width: 767px) {
                font-size: 40px;
                letter-spacing: 8px;
                margin-bottom: 20px;
            }
        }
    }

    .slick-arrow {
        position: absolute;
        bottom: calc(-6rem + 40px);
        z-index: 1;
        transition: $transition;
        cursor: pointer;
        width: 30px;
        overflow: hidden;
        img{
            width: 30px;
            &.slick-arrow-transition{
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        &.slick-next {
            left: 73px;
            img{
                transition: $transition;
                &.slick-arrow-transition{
                    transform: translateX(-40px);
                }
            }
            &:hover {
                img{
                    transform: translateX(40px);
                    &.slick-arrow-transition{
                        transform: translateX(0px);
                    }
                }
            }
        }

        &.slick-prev {
            left: 5px;
            img{
                transition: $transition;
                &.slick-arrow-transition{
                    transform: translateX(40px);
                }
            }
            &:hover {
                img{
                    transform: translateX(-40px);
                    &.slick-arrow-transition{
                        transform: translateX(0px);
                    }
                }
            }
        }
    }

    .container {
        &:last-child {
            padding-bottom: 6rem;
        }
    }
}

//Transitions
.slick-slide{
    visibility: hidden;
    cursor: pointer;
    &.slick-active.slick-current{
      visibility: visible;
        .single-slider {
            //Image
            &__image {
                img {
                    transform: scale(1);
                }
            }
            h2{
                transform: translateY(0);
            }
            h3{
                transform: translateY(0);
            }
        }
    }
}

.team-slider {
    .slick-slide {
        visibility: visible;
    }
}