.next-section {
    position: absolute;
    right: 0;
    bottom: 0;
    display: inline-block;
    padding-bottom: 70px;
    padding-left: 12px;
    border-left: 1px solid rgba($ai-white, .5);
    @media(max-width: 767px) {
        right: 15px;
    }
    &::before, &::after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 0;
        top: 0;
        left: -1px;
        position: absolute;
    }
    &::after {
        width: 3px;
        height: 20%;
        left: -2px;
        top: 0%;
        animation: upDown 3s infinite;
    }
    &::before {
        transition: all .5s;
    }
    &:hover {
        opacity: 1;
        &::before {
            height: 100%;
        }
    }
    &.light {
        color: $ai-white;
        text-decoration: none;
        font-size: 13px;

        &::before, &::after {
            background-color: $ai-white;
        }
    }
}

@keyframes upDown {
    0% {
        top: 0;
    }
    50% {
        top: 80%;
        opacity: 0;
    }
    100% {
        top: 0;
        opacity: 0;
    }
}