.careers {

    .navigation {
        background-color: transparent;
        border-bottom: 1px solid $ai-light-grey;
        &.scrolled {
            background-color: $ai-white;
        }
    }
    
    .cta {
        background-size: 100% 100%;
        .content {
            padding-top: 12rem;
            @media(max-width: 767px) { padding-top: 3rem; }
        }
    } 

    .text-banner {
        padding-top: 116px;
        @media(max-width: 767px) { padding-top: 101px; }
    }

    .accordion-wrapper {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;
    }

    .text-banner {
        .container {
            &::before {
                height: 51%;
                @media(max-width: 767px) { 
                    height: 26%;
                }
            }
        }
    }
}